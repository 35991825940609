import "packages/frontend/component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"packages/frontend/component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW7W7iOhD936ewdLUSlTCb7w/3z76KSRyYrbEj24HSVd/9yk4IcUqz3aoIiWA8mTPnnPEkv57ZuVH0wDR65dkR2hD9eUAo+OEuCBnZEoST5MeTW3LWGIJwGA5ro6jQjVQHgg7UKHhZ4WATlWuEg02ZrVGwKRO3iNM1CtYoeLT3vT0gFGYXiEvO4pLTQaaLEHnssmalhch6iDyfQcTRDCL3EJJgCaHMXdIotghh1jMqZwhJMSeRehBxuQRRFDZ3UvRU3HcxA8iSe1Mogrv7EAb3bqe3h1/zPo78Ph6Q4qB98Qy6rm9BD970Ro0+LfdxnM8g8kWIovcljSxEPFCN/tbJ0YxHuMQjdEmDyF3C6JOtHM14RMs0vtDL30/iXTPfw4xJO9+pq94eNn0Txw7nBLXZE4dr9/cMdntzXbdSgwEpCFKMUwNHZv/dSlUzhRWtodMEpX2sPDLVcHkiaA91zcTTFSuZYhWxB1WEMyS61ZJ3xiGNIriYiwL94gv0ZUsrMGdiN+26AW6YImjLO7UKo/bl0cuMpYIdCIIqJgxTw8WGUAEH2pd7GQmxRoxqhkFg2RnUSHWiqtYIRAMCejrjbbjl9Iy1oYYR1NJOs3oiV+rkuh2sOiFA7CbR2VTcsvDELfMFcb2B6Y/Lrw3Lb5P3BJzjak/FjhFX2doWu77eetOD8Ds9yP/Jg8I/TIlvQrJggupjbrb4P+rpeVVMveqfpPH04flXF0ZupeN2exLUoK0wBIHgIBhuOHPTyV6x3isQzwQFk2TUJcO4XwUEdUIz83QV70jVatx+nMr4fqeRwmANr4ygivJq5Uegnyh69Iq8VEc57AQGww6aTLrud6cNNGdcSWGYMNOtI1MGKsqxu5WgA9Q1dwp0mimsGWeVIUhIwT4am6ME288MXrn9zSqDG7Bl2Mn60dwd01afSXt7cn+DQFtaPe+U7ESNK8mluthFm8b2RavgQNW533Om3Io67cEwt+uaaSx+9Po0/JMG056qv8789pl8p8cC8Q+keq+H2m3pKgvWKAvXKHPvzanj+opB1Oxl4Lmsy3UijGd4qH/YQZsw1S5Pp7RN1EqYneea7G1DOdHGfOEkgM0O/K1xVfRvId7r2xE0bIG7fJdXAJ/eFWJSw1Uqgv5rMvuZTp8h0qttiuR+c+YNrE+j/A8hSlf5ow4AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = 'zl6vip6';
export var DefaultAvatarBottomItemWithAnimationStyle = 'zl6vip7';
export var DefaultAvatarContainerStyle = 'zl6vip3';
export var DefaultAvatarMiddleItemStyle = 'zl6vip4';
export var DefaultAvatarMiddleItemWithAnimationStyle = 'zl6vip5';
export var DefaultAvatarTopItemStyle = 'zl6vip8';
export var avatarFallback = 'zl6vipc';
export var avatarImage = 'zl6vipb';
export var avatarRoot = 'zl6vip9';
export var avatarWrapper = 'zl6vipa';
export var hoverWrapper = 'zl6vipd';
export var removeButton = 'zl6vipe';
export var sizeVar = 'var(--zl6vip0)';