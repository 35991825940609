import "packages/frontend/component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"packages/frontend/component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51TTW/bMAy951foMqA5KHBcN8Oc44D9hZ1li7a5yqIgy4nTof99EG0nzgeKtTeJj9Tje6Q2dNqVxeuz+LsSwlGHAcnmosIB9H4lBNoOQi6SeC5U+Vp76q2WJRnyuTgo/ySlqiq0IBdwS1qZMWkdK98kWg3DTcEUHbPX+9X7ajN2k/1PNxo7Z9QpF5WBIQaUwdpKDNB2uSjBBvAx/KfvAlYnWZINYMMS+mRbL9yWlOMtycV+cd1eX9PxekQdmvn9qY4daQDrJlwjW0ZatPIRmjJa0CA7fENb56Igr8HLglh+RTZECG7kcLxQHazPWcfp+SxhJ01Mmym3m12MOaU1k6SJG0SaOea4jMSDUQEP8Lm9oAN4o07SKQvL/WBVjdJ0vKl15GLNBE65rNorjX2Xi206ttaq4ayhVKZ82ibJoRFSPKdu4ELqQ1SaC0sWFmPd3WybKjoyfWBpgVwu0onCj69HR76wPd+Z5sMpNXJ3P6TdwyFlL6NoX6OVBYVA7ezFmfEHM979k7sPEQEJVn/wiaZ1kOxHlsyWMzvHVB8oxmrlZocufWzYjF9EAfxvDM3PBo32YEfjl0/fVCrOWPJcxn2lfJ7J2aAk+cYzP4CvDB3l6dLiebUTkV2xFY/9Yns0eijH/SjJ9K3dr97/ARNJtGM8BQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = 'oy6cbk6';
export var confirmModalContainer = 'oy6cbkb';
export var confirmModalContent = 'oy6cbka';
export var heightVar = 'var(--oy6cbk1)';
export var minHeightVar = 'var(--oy6cbk2)';
export var modalContent = 'oy6cbk5';
export var modalContentWrapper = 'oy6cbk4';
export var modalDescription = 'oy6cbk8';
export var modalFooter = 'oy6cbk9';
export var modalHeader = 'oy6cbk7';
export var modalOverlay = 'oy6cbk3';
export var widthVar = 'var(--oy6cbk0)';