import "packages/frontend/core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"packages/frontend/core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VVy3KjMBC8+yvmuDnIRYiNE/lrBBqwNkKi9Fg7m8q/pyTAAQwOPlGlefX0zDTbMk+xrBP43ADUzFRCEacbCi9Jczluvjbb1uF54ECBeaePPwG5dk7XFHYhBMDhxREmRaUoFKgcmkGidKESQKGlNhT+MfOHEFaWQiGJqSwWWnFmPkh0eQrOpVaOWPEfJwHx/WKjjwwPJxTVyVF43u5HKFjEMVdTCvV+rTSI2AZDG+WNDWGNFm13jyR6iSkabYUTOnCZWy29w5DFtFiT44QikmajaeyGJPbsTya2X9cgQM6K98porzgFZ5iyDTOoXDRpw9GQLsnE+PAI0rQdtLdoiEWJhaOgtMIB6iyivpva1k8Pr8vvdPcsJgMwhwimh384tPC5sI1kHxRKifEhfAkXBou2RKGlr1Ww/PXWiTIAUQ6V+zkHgHgfRDis7fD5zgm+RjQ31e/UqFhD4bVFPTuJ33m+Vn9bXKdIemNEPXOh565glgx5bW/vLLg7UdjtV6hGPopIliPW7U7HssQyKEO2rD+59HjbzW7UTRGxzYN5ZFoLG7HmzEbo9smNfmQdx1O9Hi8Yn1+wNQCui5UMFmsCaKVqNt4gOZ+Ew4k6kdkBGdZb2kNnnAtVUUj6v1GnYYZx4e31H/XoHL6+ASHm2lkpBwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = 'fb2efmc';
export var authMessage = 'fb2efm2';
export var authModalContent = 'fb2efm0';
export var captchaWrapper = 'fb2efm1';
export var forgetPasswordButton = 'fb2efm6';
export var forgetPasswordButtonRow = 'fb2efm3';
export var linkButton = 'fb2efm5';
export var resendCountdown = 'fb2efma';
export var resendCountdownInButton = 'fb2efmb';
export var resendWrapper = 'fb2efm7';
export var sendMagicLinkButtonRow = 'fb2efm4';
export var sentMessage = 'fb2efm9';
export var sentRow = 'fb2efm8';
export var userPlanButton = 'fb2efmd';